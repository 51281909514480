@import "@vmf/components/src/assets/style/_index.scss";




















































































































































































































































.question-wrap {
    > .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > .label {
            flex: 1 1 1%;
            font-size: $more * 100%;
            font-weight: bold;
            text-align: center;
        }
        > .time {
            flex: 0 0 auto;
        }
    }

    > .body {
        margin-top: $margin;
        > .label {
            font-weight: bold;
        }
        > .inner {
            > .item {
                > .el-radio-group {
                    display: block;
                    > .el-radio {
                        display: block;
                        padding: $padding 0;
                        margin-right: 0;
                    }
                }
                + .item {
                    margin-top: $margin;
                }
            }
        }
    }
}
